import { ThemeProvider } from './components/theme-provider';

import NavBar from './components/NavBar';
import Hero from './components/Hero';
import Projects from './components/Projects';
import Experiences from './components/Experiences';

function App() {

  return (
    <>
    <ThemeProvider defaultTheme="dark" storageKey="sachetd_ca_theme">
      <main className="container mx-auto max-w-4xl mt-5 flex flex-col gap-20 transition-colors">
        <NavBar />
        <Hero />

        <Projects />
        <Experiences />

        <footer className="bottom-0 py-4 text-muted-foreground">
          <div className="text-center">
            <p>© 2024 Sachet</p>
          </div>
        </footer>

      </main>
    </ThemeProvider>
    </>
  );
}

export default App;
