import { FaRegFileLines, FaLinkedin, FaGithub, FaEnvelope } from "react-icons/fa6";

export default function Hero() {
  return (
    <div className="flex flex-col gap-4 justify-center text-center items-center min-h-96">
          
      <div className="flex flex-col gap-2">
        <h1 className="text-4xl sm:text-5xl font-thin font-serif text-center">Hello, I'm Sachet</h1>
        <p className="text-muted-foreground">Toronto, ON</p>
        <div className="text-left text-lg flex flex-col gap-1">
          <p>Recently graduated from Toronto Metropolitan University with BSc in <span className="font-bold">Computer Science</span>.</p>
          <p>
          Passionate about building web applications, solving problems, and learning new technologies. Currently seeking full-time opportunities in <span className="font-semibold">Software Engineering</span>.</p>
        </div>
      </div>

        <div className="grid grid-cols-2 sm:grid-cols-4 gap-8 pt-8 justify-center text-center font-medium">

          <a href="https://sachetd.ca/resume.pdf" className="flex items-center gap-2 hover:text-accent" target="_blank" download><FaRegFileLines className="h-5 w-5" />Resume</a>

          <a href='mailto:sdawadi@torontomu.ca' className="flex items-center gap-2 hover:text-accent" target="_blank"><FaEnvelope className="h-5 w-5" />Email</a>

          <a href='https://linkedin.com/in/SachetDawadi' className="flex items-center gap-2 hover:text-accent" target="_blank"><FaLinkedin className="h-5 w-5" />LinkedIn</a>
          
          <a href='https://github.com/sachet0' className="flex items-center gap-2 hover:text-accent" target="_blank"><FaGithub className="h-5 w-5" />Github</a>

        </div>

    </div>
  )
}
