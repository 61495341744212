export default function Experiences() {

  const workExperiences = [
    {
      title: "Software Engineer Co-op",
      company: "PointClickCare",
      location: "Toronto, ON",
      duration: "2023/01 — 2023/08"
    },
    {
      title: "I&IT Digital Assistant",
      company: "Ontario Ministry of Public and Business Service Delivery and Procurement (MPBSDP)",
      location: "Toronto, ON",
      duration: "2022/05 — 2022/12"
    },
    {
      title: "Media Developer Intern",
      company: "Pearson Canada",
      location: "Toronto, ON",
      duration: "2021/05 — 2021/08"
    }
  ]

  return (
    <div className="flex flex-col gap-4">
      <h2 className="text-3xl font-thin font-serif">Experiences</h2>

      <div className="flex flex-col gap-4">
        {workExperiences.map(({ title, company, location, duration }) => (
          <div key={company}>

            <div className="flex flex-col-reverse sm:flex-row justify-between sm:gap-8">
              
              <div className="flex-shrink sm:pt-1">
                <p className="text-muted-foreground">{duration}</p>
              </div>

              <div className="text-left justify-items-start flex-1">
                <p className="text-lg font-semibold">{title}</p>
                <p className="text-muted-foreground font-semibold">{company}</p>
                <p className="text-muted-foreground">{location}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
