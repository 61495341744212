import ExpenseTrackerDarkImage from '../img/ExpenseTracker.png';
import ExpenseTrackerLightImage from '../img/ExpenseTracker_light.png';

export default function Projects() {
  return (
    <>
    {/* <div className="flex flex-col gap-2">
      <h2 className="text-3xl font-thin font-serif">Projects</h2>
      <div className="flex flex-col gap-2">
        <div>
          <p className="text-lg font-semibold">Expense Tracker</p>
          <p className="text-muted-foreground">React, Supabase/PostgreSQL</p>
        </div>
        <div className="flex flex-col gap-1">
          <p>Expense management application where users can log expenses, set budget, manage custom categories, and track expense history.</p>
          <p>React frontend with Shadcn ui library and Supabase (PostgreSQL backend) for database and authentication.</p>
        </div>
      </div>
    </div> */}

    <div className="flex flex-col gap-4">
      <h2 className="text-3xl font-thin font-serif">Projects</h2>

      {/* <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
        <a href="https://expensetracker.sachetd.ca" className="group relative grid
        grid-flow-row gap-2 overflow-hidden rounded-lg border w-full max-w-sm h-auto hover:bg-muted transition-colors duration-200
        ">
        
          <div className="transition-shadow transform group-hover:shadow-md group-hover:shadow-muted">
            <img src={ExpenseTrackerDarkImage} alt="Expense Tracker" className="object-cover hidden dark:block transform transition-transform duration-200 group-hover:scale-105"/>
            <img src={ExpenseTrackerLightImage} alt="Expense Tracker" className="object-cover block dark:hidden transform transition-transform duration-200 group-hover:scale-105"/>
            <div className="py-4 px-2 sm:px-6">
              <p className="text-xl font-semibold">Expense Tracker</p>
              <p className="text-muted-foreground">React, Supabase/PostgreSQL</p>
            </div>
          </div>
        </a>
        
      </div> */}

      <div className="grid grid-cols-1 md:grid-cols-3 sm:grid-cols-2 gap-4">
        <a href="https://expensetracker.sachetd.ca" className="group relative grid
        grid-flow-row gap-2 overflow-hidden rounded-lg w-full max-w-sm h-auto hover:bg-muted hover:border transform transition-transform duration-200
        ">
        
          <div className="transition-shadow transform group-hover:shadow-md group-hover:shadow-muted p-2">
            <img src={ExpenseTrackerDarkImage} alt="Expense Tracker" className="rounded object-cover hidden dark:block"/>
            <img src={ExpenseTrackerLightImage} alt="Expense Tracker" className="rounded object-cover block dark:hidden"/>
            <div className="py-4">
              <p className="text-xl font-semibold">Expense Tracker</p>
              <p className="text-muted-foreground">React, Supabase/PostgreSQL, Docker, GitHub Actions, Nginx</p>
            </div>
          </div>
        </a>
        
      </div>

    </div>
    </>
  )
}
